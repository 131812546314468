// ----- Server Enums ---- //

export enum Ambulant {
    NotAmbulant = 'not-ambulant',
    OneToThreeTimes = 'one-to-three-times',
    ThreeToSevenTimes = 'three-to-seven-times',
    SevenOrMore = 'seven-or-more',
    Unknown = 'unknown',
}

export enum AmbulantDevice {
    Yes = 'yes',
    No = 'no',
    Unknown = 'unknown',
}

export enum AmbulantDuration {
    OneHour = 'one-hour',
    OneToTwo = 'one-to-two',
    TwoOrMore = 'two-or-more',
}

export enum BigGroupCode {
    Physician = 1,
    Pharmacist = 17,
    PharmacyHoldingPhysician = 18,
    Nurse = 30,
    Physiotherapist = 4,
    PhysicianAssistant = 81,
    Midwife = 3,
}

export enum CaregroupType {
    GeneralPractice = 'general-practice',
    Hospital = 'hospital',
    HospitalDepartment = 'hospital-department',
    OutpatientDepartment = 'outpatient-department',
    Unknown = 'unknown',
}

export enum ComplaintSeverity {
    Absent = 'absent',
    LightToModerate = 'light-to-moderate',
    Severe = 'severe',
}

export enum Country {
    BE = 'BE',
    DE = 'DE',
    NL = 'NL',
}

export enum DiagnosisResearchMethod {
    PSG = 'polysomnography',
    PG = 'polygraphy',
}

export enum EmailVerificationStatus {
    Sent = 'sent',
    FirstReminderSent = 'first_reminder_sent',
    EmailReported = 'email_reported',
    Verified = 'verified',
}

export enum Gender {
    Female = 'female',
    Male = 'male',
    Other = 'other',
    Unknown = 'unknown',
}

export enum MedicalIndication {
    Asthma = 'asthma',
    AsthmaExacerbation = 'asthma-exacerbation',
    Bronchiectasis = 'bronchiectasis',
    BronchopulmonaryDysplasia = 'bronchopulmonary-dysplasia',
    ClusterHeadache = 'cluster-headache',
    COPD = 'copd',
    COPDExacerbation = 'copd-exacerbation',
    Covid = 'covid',
    CysticFibrosis = 'cystic-fibrosis',
    HeartFailure = 'heart-failure',
    HypoxemiaDuringExercise = 'hypoxemia-during-exercise',
    InterstitialLungDisease = 'interstitial-lung-disease',
    LungcarcinomaOrMetastases = 'lungcarcinoma-or-metastases',
    NocturnalHypoxemia = 'nocturnal-hypoxemia',
    Palliative = 'palliative',
    PersistentAsthma = 'persistent-asthma',
    PseudomonasInfection = 'pseudomonas-infection',
    SevereCOPD = 'severe-copd',
    TerminalStageDisease = 'terminal-stage-disease',
}

export enum MethodOfAdministration {
    NoseGoggles = 'nose-goggles',
    OxygenMask = 'oxygen-mask',
    OxygenMaskWithBag = 'oxygen-mask-with-bag',
    NasalCathether = 'nasal-cathether',
    TranstrachealMicrocathether = 'transtracheal-microcatheter',
}

export enum Modules {
    TrackingSystem = 'tracking-system',
}

export enum NasalInterfaceSize {
    S = 's',
    M = 'm',
    L = 'l',
    Unknown = 'unknown',
}

export enum NebulizationDevice {
    StandardDevice = 'standard-device',
    MobileDevice = 'mobile-device',
    ChildDevice = 'child-device',
    NoPreference = 'no-preference',
}

export enum NebulizationDeviceWithAntibiotics {
    NoPreference = 'no-preference',
}

export enum NotificationClassName {
    MinimumStockReachedNotification = 'App\\Modules\\TrackingSystem\\Notifications\\MinimumStockReachedNotification',
    MaximumStockReachedNotification = 'App\\Modules\\TrackingSystem\\Notifications\\MaximumStockReachedNotification',
}

export enum OxygenEquipment {
    Concentrator5Liter = 'concentrator-5-liter-per-minute',
    Concentrator9Liter = 'concentrator-9-liter-per-minute',
    EmergencyCylinder = 'emergency-cylinder',
    MobilityCylinderContinuous = 'emergency-cylinder-continuous',
    MobilityCylinderDemand = 'emergency-cylinder-demand',
    LiquidContinuous = 'liquid-continuous',
    LiquidDemand = 'liquid-demand',
    MobileConcentratorContinuous = 'mobile-concentrator-continuous',
    MobileConcentratorDemand = 'mobile-concentrator-demand',
    HomeFillingSystem = 'home-filling-system',
}

export enum PAPDeviceSetting {
    APAP = 'automatic-positive-airway-pressure',
    CPAP = 'continuous-positive-airway-pressure',
    BIPAP = 'bilevel-positive-airway-pressure',
}

export enum PEPIMTAndAccessory {
    NoPreference = 'no-preference',
    PariOPep = 'pari-o-pep',
    Aerobika = 'aerobika',
    Flutter = 'flutter',
    ShakerClassic = 'shaker-classic',
    PariPEPSystem = 'pari-pep-system',
    PariPEPSystemWithMask = 'pari-pep-system-with-mask',
    PhilipsTreshold = 'philips-treshold-pep',
    AcapellaChoice = 'acapella-choice',
    AcapellaChoiceWithMask = 'acapella-choice-with-mask',
    RMT = 'rmt',
    RMTWithMask = 'rmt-with-mask',
    PhilipsTresholdIMT = 'philips-treshold-imt',
    PhilipsTresholdWithMask = 'philips-treshold-pep-with-mask',
    PhilipsTresholdIMTWithMask = 'philips-treshold-imt-with-mask',
}

export enum Permission {
    Global_UserManagement_Create = 'global.user-management.create',
    Global_UserManagement_Overview = 'global.user-management.overview',
    Global_UserManagement_EditContactInfo = 'global.user-management.edit-contact-info',
    Global_UserManagement_ValidateBig = 'global.user-management.validate-big',
    Global_UserManagement_ChangeEmail = 'global.user-management.change-email',
    Global_UserManagement_ChangePhone = 'global.user-management.change-phone',
    Global_UserManagement_ResetPassword = 'global.user-management.reset-password',
    Global_UserManagement_Deactivate2FA = 'global.user-management.deactivate-2fa',
    Global_UserManagement_ChangeStatus = 'global.user-management.change-status',
    Global_UserManagement_ChangeType = 'global.user-management.change-type',
    Global_UserManagement_AssignToCaregroup = 'global.user-management.assign-to-caregroup',
    Global_UserManagement_AuthorizeUsers = 'global.user-management.authorize-users',
    Global_PatientManagement_Overview = 'global.patient-management.overview',
    Global_PatientManagement_Create = 'global.patient-management.create',
    Global_PatientManagement_UpdateMedicalRecord = 'global.patient-management.update-medical-record',
    Global_CaregroupManagement_Overview = 'global.caregroup-management.overview',
    Global_CaregroupManagement_AssignPatients = 'global.caregroup-management.assign-patients',
    Global_CaregroupManagement_UpdateInfo = 'global.caregroup-management.update-info',
    Global_CaregroupManagement_ManagePreferences = 'global.caregroup-management.manage-preferences',
    Global_CaregroupManagement_Modules = 'global.caregroup-management.modules',
    Global_Sap_ViewLog = 'global.sap.view-log',
    Global_Sap_EditLog = 'global.sap.edit-log',
    Global_Sap_ViewStatus = 'global.sap.view-status',
    TherapyGlobal_Concepts_Edit = 'therapy-global.concepts.edit',
    TherapyGlobal_Concepts_Submit = 'therapy-global.concepts.submit',
    TherapyGlobal_Concepts_Delete = 'therapy-global.concepts.delete',
    TherapyGlobal_Overview_View = 'therapy-global.overview.view',
    Therapy_Oxygen_View = 'therapy.oxygen.view',
    Therapy_Oxygen_Validate = 'therapy.oxygen.validate',
    Therapy_NebulizationWithAntibiotics_View = 'therapy.nebulization-with-antibiotics.view',
    Therapy_NebulizationWithAntibiotics_Validate = 'therapy.nebulization-with-antibiotics.validate',
    Therapy_NebulizationWithOutAntibiotics_View = 'therapy.nebulization-without-antibiotics.view',
    Therapy_ClusterHeadache_View = 'therapy.cluster-headache.view',
    Therapy_ClusterHeadache_Validate = 'therapy.cluster-headache.validate',
    Therapy_Be_View = 'therapy.bronchiectasis.view',
    Therapy_Be_Validate = 'therapy.bronchiectasis.validate',
    Therapy_Pep_View = 'therapy.positive-expiratory-pressure.view',
    Therapy_Cf_View = 'therapy.cystic-fibrosis.view',
    Therapy_Cf_Validate = 'therapy.cystic-fibrosis.validate',
    Therapy_Pap_View = 'therapy.positive-airway-pressure.view',
    Therapy_Pap_ValidateApproval = 'therapy.positive-airway-pressure.validate-approval',
    Therapy_Spt_View = 'therapy.position-therapy.view',
    Therapy_Spt_ValidateApproval = 'therapy.position-therapy.validate-approval',
    Therapy_NasalHighFlow_View = 'therapy.nasal-high-flow.view',
    Portal_CounterReading_Manage = 'portal.counter-reading.manage',
    Portal_CounterReading_Insert = 'portal.counter-reading.insert',
    Portal_CounterReading_View = 'portal.counter-reading.view',
    Portal_CounterReading_Edit = 'portal.counter-reading.edit',
    Portal_Orders_AddNewProducts = 'portal.orders.add-new-products',
    Portal_Orders_Overview = 'portal.orders.overview',
    Portal_Orders_Edit = 'portal.orders.edit',
    Module_TrackingSystem_Global_Manage = 'module.tracking-system.global.manage',
    Module_TrackingSystem_Global_View = 'module.tracking-system.global.view',
    Module_TrackingSystem_Orders_ViewAll = 'module.tracking-system.orders.view-all',
    Module_TrackingSystem_Orders_View = 'module.tracking-system.orders.view',
    Module_TrackingSystem_Orders_Edit = 'module.tracking-system.orders.edit',
    Module_TrackingSystem_Orders_Create = 'module.tracking-system.orders.create',
    Module_TrackingSystem_Orders_View_Price = 'module.tracking-system.orders.view-price',
    Module_TrackingSystem_Locations_ViewAll = 'module.tracking-system.locations.view-all',
    Module_TrackingSystem_Locations_View = 'module.tracking-system.locations.view',
    Module_TrackingSystem_Locations_Edit = 'module.tracking-system.locations.edit',
    Module_TrackingSystem_Locations_Create = 'module.tracking-system.locations.create',
    Module_TrackingSystem_Locations_Delete = 'module.tracking-system.locations.delete',
    Module_TrackingSystem_Stock_View = 'module.tracking-system.stock.view',
    Module_TrackingSystem_Stock_Edit = 'module.tracking-system.stock.edit',
    Module_TrackingSystem_Stock_Delete = 'module.tracking-system.stock.delete',
    Module_TrackingSystem_Invoices_View = 'module.tracking-system.invoices.view',
    Module_TrackingSystem_Invoices_ViewAll = 'module.tracking-system.invoices.view-all',
    Module_TrackingSystem_Invoices_Download = 'module.tracking-system.invoices.download',
    Module_TrackingSystem_Barcodes_View = 'module.tracking-system.barcodes.view',
}

export enum PhoneNumberType {
    Home = 'home',
    Work = 'work',
    Mobile = 'mobile',
}

export enum Role {
    Developer = 'developer',
    Admin = 'admin',
    AccountManager = 'account-manager',
    CustomerService = 'customer-service',
    Pharmacist = 'pharmacist',
    Physician = 'physician',
    PhysicianAssistant = 'physician-assistant',
    Physiotherapist = 'physiotherapist',
    Midwife = 'midwife',
    Nurse = 'nurse',
    PolyclinicEmployee = 'polyclinic-employee',
    Patient = 'patient',
    CarePortalManager = 'care-portal-manager',
    CarePortalUser = 'care-portal-user',
    CarePortalViewer = 'care-portal-viewer',
    CarePortalAccountManager = 'care-portal-account-manager',
    CarePortalCustomerService = 'care-portal-customer-service',
    Unknown = 'unknown',
    None = 'none',
}

export enum SapStatus {
    Failed = 'failed',
    InQueue = 'in-queue',
    Inserting = 'inserting',
    Succeeded = 'succeeded',
    ToBeQueued = 'to-be-queued',
    Unknown = 'unknown',
}

export enum SmokerRoommates {
    Yes = 'yes',
    No = 'no',
    Unknown = 'unknown',
}

export enum TherapyRequestEnvironment {
    Auth = 'auth',
    Guest = 'guest',
}

export enum TherapyRequestType {
    New = 'new',
    Mutation = 'mutation',
    Stop = 'stop',
    Approval = 'approval',
}

export enum TherapyStartingDate {
    WithinFourHours = 'within-4-hours',
    WithinOneDay = 'within-24-hours',
    WithinTwoDays = 'within-48-hours',
    WithinSevenDays = 'within-7-days',
}

export enum TherapyStatus {
    Concept = 'concept',
    Processed = 'processed',
    RequiresValidation = 'requires-validation',
    Rejected = 'rejected',
    Unknown = 'unknown',
}

export enum TherapyType {
    Oxygen = 'oxygen',
    ClusterHeadache = 'cluster-headache',
    NebulizationWithoutAntibiotics = 'nebulization-without-antibiotics',
    NebulizationWithAntibiotics = 'nebulization-with-antibiotics',
    BE = 'bronchiectasis',
    CF = 'cystic-fibrosis',
    PEP = 'positive-expiratory-pressure',
    PAP = 'positive-airway-pressure',
    SPT = 'position-therapy',
    NasalHighFlow = 'nasal-high-flow',
}

export enum UserStatus {
    Approved = 'approved',
    Blocked = 'blocked',
    Concept = 'concept',
    ExpiredBigRegistration = 'expired-big',
    Rejected = 'rejected',
    Unverified = 'unverified',
}

export enum CaregroupPreferenceName {
    SkipApprovalRequestValidation = 'skip-approval-request-validation',
    TwoFactor = 'two-factor',
    VerifyEmailAfterDays = 'verify-email-after-days',
}

export enum TrackingSystemInvoiceStatus {
    Unpaid = 'unpaid',
    Credited = 'credited',
    Paid = 'paid',
}

export enum TrackingSystemLocationStatus {
    Active = 'active',
    Inactive = 'inactive',
}

export enum TrackingSystemLocationType {
    Room = 'room',
    Vehicle = 'vehicle',
    Wheelchair = 'wheelchair',
    Warehouse = 'warehouse',
}

export enum TrackingSystemOrderStatus {
    Created = 'created',
    Submitted = 'submitted',
    Failed = 'failed',
}

export enum TrackingSystemPartnerFunctionType {
    InvoiceAddress = 'RE',
    DeliveryAddress = 'WE',
}

// ----- Client Enums ---- //

export enum ClassName {
    Address = 'App\\Models\\Address',
    BigMention = 'App\\Models\\BigMention',
    BigRegistration = 'App\\Models\\BigRegistration',
    Caregroup = 'App\\Models\\Caregroup',
    CaregroupModule = 'App\\Models\\CaregroupModule',
    CaregroupPreference = 'App\\Models\\CaregroupPreference',
    Contact = 'App\\Models\\Contact',
    CounterReading = 'App\\Models\\CounterReading',
    EmailVerification = 'App\\Models\\EmailVerification',
    Media = 'App\\Models\\Media',
    Notification = 'App\\Models\\Notification',
    NotificationTemplate = 'App\\Models\\NotificationTemplate',
    PatientInfo = 'App\\Models\\PatientInfo',
    PhoneNumber = 'App\\Models\\PhoneNumber',
    TherapyRequest = 'App\\Models\\TherapyRequest',
    TwoFactorCode = 'App\\Models\\TwoFactorCode',
    User = 'App\\Models\\User',
    TrackingSystemBarcode = 'App\\Modules\\TrackingSystem\\Models\\Barcode',
    TrackingSystemCaregroupFunction = 'App\\Modules\\TrackingSystem\\Models\\CaregroupFunction',
    TrackingSystemCaregroupMaterial = 'App\\Modules\\TrackingSystem\\Models\\CaregroupMaterial',
    TrackingSystemCaregroupPartnerFunction = 'App\\Modules\\TrackingSystem\\Models\\CaregroupPartnerFunction',
    TrackingSystemFilling = 'App\\Modules\\TrackingSystem\\Models\\Filling',
    TrackingSystemInventory = 'App\\Modules\\TrackingSystem\\Models\\Inventory',
    TrackingSystemInvoice = 'App\\Modules\\TrackingSystem\\Models\\Invoice',
    TrackingSystemLabel = 'App\\Modules\\TrackingSystem\\Models\\Label',
    TrackingSystemLocation = 'App\\Modules\\TrackingSystem\\Models\\Location',
    TrackingSystemLocationChange = 'App\\Modules\\TrackingSystem\\Models\\LocationChange',
    TrackingSystemMaterial = 'App\\Modules\\TrackingSystem\\Models\\Material',
    TrackingSystemOrder = 'App\\Modules\\TrackingSystem\\Models\\Order',
    TrackingSystemOrderItem = 'App\\Modules\\TrackingSystem\\Models\\OrderItem',
    TrackingSystemPartner = 'App\\Modules\\TrackingSystem\\Models\\Partner',
    TrackingSystemStock = 'App\\Modules\\TrackingSystem\\Models\\Stock',
}

export enum HttpResponseCode {
    OK = 200,
    Created = 201,
    NoContent = 204,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
    InvalidInput = 422,
}

